.container-page{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.container-card-one{
  display: flex;
  width: 75%;
  flex-direction: row;
  gap: 25px;
}

.card-me{
  padding: 40px 36px;
  display: flex;
  border: 1px solid #282828;
  width: 40%;
  gap: 30px;
  border-radius: 14px;
  background: rgb(0,11,14);
  background: linear-gradient(340deg, #131313 50%, #212121 100%);
  animation: open 1s ease-in-out;
}

.card-me:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}

.container-me{
  width: 60%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name{
  font-size: 36px;
  margin-bottom: 12px;
  line-height: 40px;
  font-weight: 500;
  cursor: default;
}
.p{
  color: #BCBCBC;
  opacity: 0.7;
  letter-spacing: 2px;
  margin-bottom: 3px;
  font-size: 14px;
  cursor: default;
}
.container-image{
  width: 60%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #3C58E3 0%, #C2EBFF 50%, #5AB5E2 95%);
  border-radius: 20px 0 20px 0;
  overflow: hidden;
}
.image-me{
  width: 100%;
 scale: 1.1;
 overflow: hidden;
}
.right-card{
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 7%;
  
}
.news-bar{
  background: rgb(0,11,14);
  background: linear-gradient(340deg, #131313 50%, #212121 100%);
  border: 1px solid #282828;
  padding: 10px;
  height: 10%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  animation: open 1s ease-in-out;
  cursor: default;
}
h1,h2,h3,h4,h5,h6{
  cursor: default;
}
.news-bar:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}

.container-two-card{
  display: flex;
  width: 100%;
  height: 80%;
  gap: 20px;
}
.card-credentials{
  display: flex;
  border: 1px solid #282828;
  flex-direction: column;
  justify-content: center;
  width: 49%;
  padding: 24px;
  border-radius: 14px;
  background: rgb(0,11,14);
  background: linear-gradient(340deg, #131313 50%, #212121 100%);
  animation: open 1s ease-in-out;
}
.card-credentials:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}

.image-signature{
  width: 50%;
}

.container-action-go{
  display: flex;
}
.container-action{
  width: 90%;
  text-align: start;
}
.container-go{
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-card-two{
  display: flex;
  width: 75%;
  flex-direction: row;
  gap: 20px;
}

.container-action-skills{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.container-skills{
  display: flex;
  flex-direction: column;
  background: rgb(0,11,14);
  background-image: linear-gradient(350deg, #131313 10%, #212121 100%);
  width: 70%;
  padding: 24px;
  border: 1px solid #282828;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  transition: background-image 3s ease-in-out;
  animation: open 1s ease-in-out;
}
.container-skills:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}
.skills-images{
  width: 7%;
}
.dv{
  width: 10%;
}
.skills-images:hover{
  transform: scale(1.2);
  cursor: default;
}

.container-skills-image{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 14px;

}
.container-contact{
  background: rgb(0,11,14);
  background: linear-gradient(340deg, #131313 50%, #212121 100%);
  width: 30%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  gap: 14px;
  border: 1px solid #282828;
  animation: open 1s ease-in-out;
}
@keyframes open {
  from{
    transform: scale(0);
  }
  to{
    transform: scale(1);
  }
}
.container-contact:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}

.container-card-three{
  display: flex;
  width: 75%;
  justify-content: end;
  flex-direction: row;
  gap: 20px;
}

.container-contact-let{
  background: rgb(0,11,14);
  background: linear-gradient(340deg, #131313 50%, #212121 100%);
  width: 40%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-radius: 14px;
  gap: 14px;
  border: 1px solid #282828;
  animation: open 1s ease-in-out;


}
.container-contact-let:hover{
  transform: scale(1.02);
  box-shadow: 0 0 5px 1px #131313;
}
.container-go {
  opacity: 0.5;
}
.cont:hover >.container-go{
  opacity: 1;
}
.cont:hover > .container-action-go >.container-go{
  opacity: 1;
}
@media only screen and (max-width: 600px) {

  .container-card-one{
    width: 85%;
    height: auto;
    flex-direction: column;
  }  
  .card-me{
    width: auto;
    flex-direction: column;
  }
  .container-image{
    width: auto;
  }
  .container-me{
    width: auto;
  }
  .right-card{
    justify-content: center;
    align-items: center;
    width: 85vw;
  }
  .news-bar{
    width: 80vw;
    margin-bottom: 10px;
  }
  .container-two-card{
    flex-direction: column;
    width: 85vw;
  }
  .card-credentials{
    width: auto;
  }
  .container-card-two{
    width: 85%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .container-card-three{
    width: 90%;
  }
  .container-skills{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    height: auto;
  }
  .container-skills-image{
    flex-wrap: wrap;
    gap: 5px;
    flex: 0 0 calc(50% - 10px);
  }
  .skills-images{
    width: 20%;
    margin: 10px;
  }
  .dv{
    width: 40%;
  }
  .container-contact{
    width: 90%;
  }
  .container-contact-let{
    width: 100%;
  }
}