*{
  padding: 0;
  margin: 0;
}
.App {
  padding: 0;
  margin: 0;
  text-align: center;
  justify-content: center;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #ffffff;
  background: linear-gradient(330.7deg, #131313 50%, #222222 100%);

}




::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
  height: 4px;
  background-color: #CDD7D6;
}

::-webkit-scrollbar-thumb {
  background-color: #1A181B;

}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.App{
  height: auto;
  padding-bottom: 25px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}