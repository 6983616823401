.contanier-nav-bar{
display: flex;
justify-content: center;
}

.child-nav-bar{
    display:flex;
    width: 74%;
    justify-content:space-between;
    text-align: center;
    align-items: center;
    font-size:14px;
    padding:10px;
    margin:15px;
    margin-right: 10px;
    padding-right: 0;
    border-radius:12px;
}
.continar-btn-nav{
    display: flex;
}
.btn-nav{
    padding: 10px;
    font-weight: bold;
    border: 1px solid #282828;
    background: linear-gradient(340deg, #131313 0%, #212121 100%);
    color: white;
    border-radius: 6px;
}
.btn-nav:hover{
    box-shadow: 0 0 5px 1px #131313;
    cursor: pointer;
}

.btn-nav:active{
    transform: scale(0.97);
    padding: 10px;
    font-weight: bold;
    border: 1px solid #282828;
    background: linear-gradient(340deg, #131313 0%, #212121 100%);
    color: white;
    border-radius: 6px;
}
.title{
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    width: 10%;
}
@media only screen and (max-width: 600px) {
    .title{
        width: 20%;
    }

}